import React, { useState } from 'react'
import { LoremIpsum } from 'lorem-ipsum'
import Textarea from '../components/Textarea'

const LoremIpsumGenerator = () => {
    const [text, setText] = useState('')
    const [outputType, setOutputType] = useState(`plain`)
    const [wordsPerSentence, setWordsPerSentence] = useState(10)
    const [sentencesPerParagraph, setSentencesPerParagraph] = useState(5)
    const [paragraphsInText, setParagraphsInText] = useState(3)
    const [startWithLorem, setStartWithLorem] = useState(true)

    const generate = e => {
        e.preventDefault()

        const opts = {
            sentencesPerParagraph: {
                max: parseInt(sentencesPerParagraph),
                min: parseInt(sentencesPerParagraph),
            },
            wordsPerSentence: {
                max: parseInt(wordsPerSentence),
                min: parseInt(wordsPerSentence),
            },
        }

        const lorem = new LoremIpsum(
            opts,
            outputType === `plain` ? `plain` : `html`
        )

        let paragraphs = lorem.generateParagraphs(parseInt(paragraphsInText))

        if (outputType === `plain`) {
            paragraphs = paragraphs.replace(/\n/g, `\n\n`)
        }

        if (startWithLorem === true) {
			if (outputType === `plain`) {
				paragraphs = `Lorem ipsum dolor sit amet ${paragraphs.charAt(0).toLowerCase() + paragraphs.substring(1)}`
			} else {
				paragraphs = `<p>Lorem ipsum dolor sit amet ${paragraphs.charAt(3).toLowerCase() + paragraphs.substring(4)}`
			}
        }

        setText(paragraphs)
    }

    return (
		<div className="columns is-desktop">
			<form className="column" onSubmit={generate}>
				<div className="field">
					<label className="label" htmlFor="paragraphsInText">
						Paragraphs
					</label>
					<div className="control">
						<input
							className="input"
							type="number"
							id="paragraphsInText"
							min="1"
							max="99999"
							placeholder="1"
							value={paragraphsInText}
							onChange={e =>
								setParagraphsInText(
									parseInt(e.target.value)
								)
							}
						/>
					</div>
					<p className="help">
						How many paragraphs do you want in your text?
					</p>
				</div>

				<div className="field">
					<label
						className="label"
						htmlFor="sentencesPerParagraph"
					>
						Sentences per Paragraph
					</label>
					<div className="control">
						<input
							className="input"
							type="number"
							id="sentencesPerParagraph"
							min="1"
							max="500"
							placeholder="1"
							value={sentencesPerParagraph}
							onChange={e =>
								setSentencesPerParagraph(
									parseInt(e.target.value)
								)
							}
						/>
					</div>
					<p className="help">
						How many sentences do you want in each paragraph?
					</p>
				</div>

				<div className="field">
					<label className="label" htmlFor="wordsPerSentence">
						Words per Sentence
					</label>
					<div className="control">
						<input
							className="input"
							type="number"
							id="wordsPerSentence"
							min="1"
							max="500"
							placeholder="1"
							value={wordsPerSentence}
							onChange={e =>
								setWordsPerSentence(
									parseInt(e.target.value)
								)
							}
						/>
					</div>
					<p className="help">
						How many words do you want in each sentence?
					</p>
				</div>

				<div className="field">
					<div className="control">
						<label className="checkbox" htmlFor="startWithLorem">
							<input
								className="checkbox"
								type="checkbox"
								id="startWithLorem"
								placeholder="1"
								checked={startWithLorem}
								onChange={e =>
									setStartWithLorem(Boolean(e.target.checked))
								}
							/>{` `}
							Start with <em>Lorem ipsum</em>
						</label>
					</div>
				</div>

				<div className="field">
					<label className="label">Output Type</label>

					<label htmlFor="outputTypePlain" className="radio">
						<input
							id="outputTypePlain"
							type="radio"
							name="outputType"
							value={`plain`}
							checked={`plain` === outputType}
							onChange={() => setOutputType(`plain`)}
						/>
						{` `}
						Plain
					</label>

					<label htmlFor="outputTypeHtml" className="radio">
						<input
							id="outputTypeHtml"
							type="radio"
							name="outputType"
							value={`html`}
							checked={`html` === outputType}
							onChange={() => setOutputType(`html`)}
						/>
						{` `}
						HTML
					</label>
				</div>

				<div>
					<button
						type="submit"
						className="button is-primary"
					>
						Generate
					</button>
				</div>
			</form>

			<div className="column is-four-fifths">
				<Textarea
					className="textarea"
					style={{
						height: `50vh`,
						width: `100%`,
						backgroundColor: `#fff`,
						padding: `1rem`,
						fontFamily: `monospace`,
					}}
					value={text}
					showCopyToClipboard={true}
					readOnly={true}
				/>
			</div>
		</div>
    )
}

LoremIpsumGenerator.propTypes = {}

LoremIpsumGenerator.defaultProps = {}

export default LoremIpsumGenerator
