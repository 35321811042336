import React from 'react'

const Textarea = (props) => {

	const textArea = React.createRef();

	const copyToClipboard = () => {
		const input = textArea.current;
		input.select()
		document.execCommand("copy")
	}

	const {
		className,
		value=``,
		onChange=undefined,
		showCopyToClipboard=true,
		readOnly=false,
		disabled=false,
		...rest
	} = props

	return (
		<div>
			<div style={{ marginBottom: `1rem` }}>
				{showCopyToClipboard ? <button className="button" onClick={() => copyToClipboard()}>copy all to clipboard</button> : ``}
			</div>
			<textarea 
				ref={textArea}
				className={`textarea ${className}`}
				value={value}
				onChange={onChange}
				readOnly={readOnly}
				disabled={disabled}
				{...rest}
			/>
		</div>
	)
}

export default Textarea
