import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import LoremIpsumGenerator from '../../tools/LoremIpsumGenerator'

const LoremIpsumGeneratorPage = () => (
    <Layout>
        <SEO title="Lorem Ipsum Generator – Instantly generate lorem ipsum dummy Text and HTML" />
		<section className="hero is-primary">
            <div className="hero-body">
                <div className="container is-fluid">
                    <h1 className="title">Lorem Ipsum Generator</h1>
                    <h2 className="subtitle">
                        Generate lorem ipsum text to use a placeholder dummy content in your website development or programming adventures. It is customizable so you can be specific for your number of words, sentences, and paragraphs.
                    </h2>
                </div>
            </div>
        </section>

		<section className="section">
            <div className="container is-fluid">
				<LoremIpsumGenerator />
            </div>
        </section>

    </Layout>
)

export default LoremIpsumGeneratorPage
